<template>
  <tr>
    <td>{{ userId }}</td>
    <td v-if="isAdmin">
      <a @click.prevent="goToUserCalendar">{{ name }}</a>
    </td>
    <td v-else>{{ name }}</td>
    <td></td>
    <td></td>
    <td></td>
    <td>{{ hrvDeviation }}</td>
    <td>{{ ftp }}</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td>
      <a v-if="isAdmin && telegramChatId" @click="showSendMessageModal(telegramChatId, name, userId, telegramUserName)"
        ><svg-icon icon="telegram2"
      /></a>
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import configuration from "@/configuration"
const { appUrl } = configuration

export default {
  name: "StudentItem",
  props: {
    student: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState(["isEmbedded"]),

    ftp() {
      return this.student.ftp
    },
    userId() {
      return this.student.userId
    },
    name() {
      return this.student.name
    },
    telegramChatId() {
      return this.student.telegramChatId
    },
    telegramUserName() {
      return this.student.telegramUserName
    },
    hrvDeviation() {
      if (!this.student.hrvDeviation) return
      return `${this.student.hrvDeviation} %`
    }
  },
  methods: {
    goToUserCalendar() {
      if (!this.isAdmin || !this.student.userId) return

      if (this.isEmbedded) {
        window.parent.postMessage(
          {
            type: "open-calendar-from-admin-area",
            userId: this.student.userId
          },
          appUrl
        )
      } else {
        window.location.href = `https://trainerday.com/login?redirect=${appUrl}/calendar&want-to-be=${this.student.userId}`
      }
    },
    showSendMessageModal(telegramChatId, name, userId, telegramUserName) {
      this.$emit("showModal", telegramChatId, name, userId, telegramUserName)
    }
  }
}
</script>

<style lang="scss" scoped></style>
