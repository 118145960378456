
import StudentItem from "@/components/student/StudentItem.vue"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { Component, Vue } from "vue-property-decorator"
import SendMessageModal from "@/components/student/SendMessageModal.vue"

@Component({
  name: "Students",
  components: { StudentItem, SendMessageModal },
  data() {
    return {
      showSendMessageModal: false,
      userId: null,
      name: "",
      telegramChatId: null
    }
  },
  computed: {
    ...mapState("student", ["studentList"]),
    ...mapMutations("student", ["setStudents"]),
    ...mapGetters("student", ["studentList"])
  },
  methods: {
    ...mapActions("student", ["getStudents"])
  }
})
export default class Student extends Vue {
  getStudents!: Function
  studentList!: []
  showSendMessageModal!: boolean
  userId!: number
  telegramChatId!: number
  name!: string
  telegramUserName!: string
  async mounted() {
    if (!this.studentList.length) await this.getStudents()
  }
  showModal(telegramChatId: number, name: string, userId: number, telegramUserName: string) {
    this.telegramChatId = telegramChatId
    this.userId = userId
    this.name = name
    this.telegramUserName = telegramUserName
    this.showSendMessageModal = true
  }
  sendMessage() {
    this.showSendMessageModal = false
  }
}
