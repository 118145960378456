<template>
  <BlueHeaderModal @close="$emit('close')">
    <template v-slot:header>
      <label class="label">{{ $t("sendMessageModal.title") }}</label>
    </template>
    <template v-slot:body>
      <div class="right" v-if="!isLoading">
        <div class="center">
          <label class="label">{{ $t("sendMessageModal.description") }} {{ name }}</label>
          <textarea name="" id="" class="text-area" v-model="message"></textarea>
        </div>
      </div>
      <div v-else class="loader-container">
        <img class="page-loader-icon" src="@/assets/images/loader.gif" alt="Loader" />
      </div>
    </template>
    <template v-slot:footer>
      <a class="button connect-telegram is-full-width" @click="send" :disabled="disableButton">
        <img src="@/assets/images/telegram-logo.svg" alt="Telegram" />
        <span>{{ $t("sendMessageModal.send") }}</span>
      </a>
    </template>
  </BlueHeaderModal>
</template>

<script>
import { sendTelegramMessage, sendTelegramMessageToReplyGroup } from "@/data"
import { mapGetters } from "vuex"
import { ADD_TEXT1_TO_MESSAGE_FOR_STUDENT, ADD_TEXT2_TO_MESSAGE_FOR_STUDENT } from "@/defaults"

export default {
  name: "SendMessageModal",
  data() {
    return {
      message: "",
      isLoading: false
    }
  },
  props: {
    telegramChatId: {
      type: Number,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    userId: {
      type: Number,
      default: null
    },
    telegramUserName: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    disableButton() {
      return !this.message || this.message === "Something went wrong"
    }
  },
  methods: {
    async send() {
      if (this.disableButton) return
      this.isLoading = true
      const messageForStudent =
        ADD_TEXT1_TO_MESSAGE_FOR_STUDENT + "\n" + this.message + "\n\n" + ADD_TEXT2_TO_MESSAGE_FOR_STUDENT

      const res = await sendTelegramMessage(this.telegramChatId, messageForStudent)

      const messageForReplyGroup = this.telegramUserName
        ? this.message + "\nto: #" + this.telegramUserName + " userId: " + this.userId
        : this.message + "\nuserId: " + this.userId
      if (res.data === "error") {
        this.message = "Something went wrong"
      } else {
        await sendTelegramMessageToReplyGroup(messageForReplyGroup)
        this.isLoading = false
        this.$emit("sendMessage", this.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
.loader-container {
  height: 164px;
}
::v-deep {
  .blue-header-modal-container {
    max-width: 450px;
    min-width: 450px;
  }
  .blue-header-modal-header {
    padding: 20px 15px;
    .field {
      width: 100%;
      .label {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    .input {
      &::placeholder {
        color: black;
        opacity: 0.7;
      }
    }
  }
  .blue-header-modal-body {
    display: flex;
    flex-direction: column;
    padding: 10px 30px 30px;
    gap: 30px;

    @include media("<phone") {
      flex-direction: column;
      padding: 20px;
    }

    .label {
      font-weight: 500;
      color: #131415;
    }

    .text-area {
      width: 100%;
      resize: none;
      margin: 0;
      height: 130px;
      border-color: #d6d6d6;
      border-radius: 8px;
      padding: 15px;
    }
  }
}
.connect-telegram {
  border: 2px solid #2ab6f5;
  background: #2ab6f5;
  color: white;
  span {
    padding: 0 12px;
  }

  svg {
    max-height: 100%;
  }
}
</style>
